import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Layout from '../../components/Layout';
import Product from '../../components/Product';
import SlideBar from './SlideBar';
import PageTitle from '../../components/PageTitle/PageTitle';
import { mediaQueries } from '../../utils/mediaQueries';
import { getSubType } from '../../utils/common';
import { ProductDescription } from '../../components/Product/Description';

const ProductList = styled.div`
  width: 767px;
  ${mediaQueries('md')} {
    width: auto;
  }
`;

const SlideBarStyled = styled.div`
  padding-top: 50px;
  width: ${(props) => (props.theme.name === 'men' ? '200px' : '183px')};
`;

const Products = ({ data, pageContext }) => {
  const { t,i18n } = useTranslation();
  const { allProduct, allMenu } = data;
  const { allProducts } = allProduct;
  const { gender, breadcrumbName, type, locale } = pageContext;
  const genderPath = gender === 'men' ? '/men' : '';
  const { isMobileView } = useSelector((state) => state.common);

  let pageName = breadcrumbName[breadcrumbName.length - 1];
  const menuData = allMenu.nodes[0].types.filter((item) => {
    return item.slug === type;
  });

  let setTitle = `${breadcrumbName[0]} ${breadcrumbName[1]}`;
  let description = null;

  if (getSubType(pageContext) !== null) {
    setTitle = `${breadcrumbName[0]} ${breadcrumbName[3]}`;

    const type = getSubType(pageContext);
    description = menuData[0][type].find((item) => {
      return item.slug === pageContext[type];
    })?.localizations?.[locale]?.description;
  }

  const ViewProductList = () => {


    const allProductsList = allProducts.map((item, index) => {
      const prices = item.content.price.split('/');
      let firstPrice = prices[0];
      firstPrice = firstPrice.replace('HK$', '').replace(',', '');
      firstPrice = parseInt(firstPrice, 10);

      const volumes = item.content.volume.split('/');
      let firstVolume = volumes[0];

      return {
        brand: 'BIOTHERM HK',
        category: breadcrumbName[3],
        id: item.slug,
        name: item.content.name,
        list: breadcrumbName[1],
        price: firstPrice,
        position: index,
        variant: firstVolume,
      };
    });

    //console.log(pageContext)

    window.dataLayer.push({
      ecommerce: {
        currencyCode: 'HKD',
        impressions: {
          products: allProductsList
        }
      },
      event: 'nievent',
      event_name: 'view_item_list',
      eventAction: 'Product Impressions',
      eventCategory: 'Ecommerce',
      eventLabel: 'Product listing page',
      page_category: 'product listing page',
      site_language: i18n.language,
      brand: 'BIO',
      country: 'hk',
    });
  };

  const handleClickProduct = (product) => {
    //console.log('handleClickProduct')

    const allProductsList = allProducts.map((item, index) => {
      const prices = item.content.price.split('/');
      let firstPrice = prices[0];
      firstPrice = parseInt(firstPrice.replace('HK$', ''));

      const volumes = item.content.volume.split('/');
      let firstVolume = volumes[0];

      return {
        brand: 'BIOTHERM HK',
        category: breadcrumbName[3],
        id: item.slug,
        name: item.content.name,
        list: breadcrumbName[1],
        price: firstPrice,
        position: index,
        variant: firstVolume,
      };
    });

    //console.log(allProductsList[product])

    window.dataLayer.push({
      ecommerce: {
        click: {
          actionField: {
            action: 'click',
            list: breadcrumbName[3],
            products: allProductsList[product]
          }
        },
      },
      event: 'productClick',
      event_name: 'select_item',
      eventAction: 'Product Click',
      eventCategory: 'Ecommerce',
      eventLabel: `${allProductsList[product].name}::${allProductsList[product].id}`,
      page_category: 'product listing page',
      site_language: i18n.language,
      brand: 'BIO',
      country: 'hk',
    });
  };

  useEffect(() => {
    ViewProductList()
  }, []);



  return (
    <Layout
      fullwidth={isMobileView}
      seoSettings={{ title: setTitle, description: `Biotherm ${setTitle}` }}
    >
      <div id="category" style={{ display: 'none' }}>{pageName}</div>
      <PageTitle>{pageName}</PageTitle>

      <div
        className={`row no-gutters justify-content-between align-items-start`}
      >
        {!isMobileView && (
          <SlideBarStyled>
            <SlideBar items={menuData[0]} />
          </SlideBarStyled>
        )}
        <div className="col-md-auto" style={{ maxWidth: 767 }}>
          {!isMobileView && <PageTitle show={true}>{pageName}</PageTitle>}
          {!isMobileView && description && (
            <ProductDescription>{description}</ProductDescription>
          )}
          <ProductList className={`row no-gutters `}>
            {allProducts.map((node, index) => {
              const { name, short_description } = node.content;
              return (
                <Product
                  key={node.id}
                  id={node.id}
                  classes={{ container: `col-md-4 col-sm-6 ${gender}` }}
                  image={node.remoteImage.childImageSharp.fluid}
                  gender={gender}
                  title={name}
                  description={short_description}
                  path={`${genderPath}/product/${node.slug}`}
                  onClickFunction={() => handleClickProduct(index)}
                />
              );
            })}
          </ProductList>
        </div>
      </div>
    </Layout>
  );
};

export default Products;

export const pageQuery = graphql`
  query(
    $locale: String
    $gender: String
    $type: String
    $categories: [String]
    $needs: [String]
    $series: [String]
  ) {
    allProduct(
      filter: {
        types: { elemMatch: { slug: { eq: $type } } }
        genders: { elemMatch: { slug: { eq: $gender } } }
        categories: { elemMatch: { slug: { in: $categories } } }
        needs: { elemMatch: { slug: { in: $needs } } }
        series: { elemMatch: { slug: { in: $series } } }
      }
      sort: { order: ASC, fields: [series___sequence, sequence] }
    ) {
      allProducts: nodes {
        id
        slug
        content(locale: $locale) {
          name
          short_description
          price
          volume
        }
        remoteImage {
          childImageSharp {
            fluid(maxWidth: 105) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    allMenu(filter: { slug: { eq: $gender } }) {
      nodes {
        slug
        types {
          id
          slug
          categories {
            sequence
            count
            slug
            localizations {
              en {
                name
              }
              zh {
                name
              }
            }
          }
          needs {
            sequence
            count
            slug
            localizations {
              en {
                name
              }
              zh {
                name
              }
            }
          }
          series {
            sequence
            count
            slug
            localizations {
              en {
                name
                description
              }
              zh {
                name
                description
              }
            }
          }
        }
      }
    }
  }
`;
