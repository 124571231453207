import styled, { css } from 'styled-components';
import { mediaQueries } from '../../utils/mediaQueries';
import Link from '../Link';
import BgPoint from '../../assets/images/product/bg-point.jpg';

export const Header = styled.h2`
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
  font-size: 28px;
  color: ${(props) => props.theme.relatedProduct.titleColor};
  margin: 30px 0;
`;

export const Container = styled.div`
  width: ${(props) => props.theme.relatedProduct.containerSize};
  margin: 0 auto;

  ${mediaQueries('md')} {
    width: 100%;
  }
`;

export const Box = styled.div`
  max-width: ${(props) => props.theme.relatedProduct.boxSize};
  flex: 0 0 ${(props) => props.theme.relatedProduct.boxSize};
  border: 1px solid #889bac;
  padding: 15px 0;
  font-family: Arial, Helvetica, sans-serif;

  ${mediaQueries('md')} {
    max-width: 100%;
    flex: 0 0 100%;
  }

  &:not(:last-child) {
    margin-right: 10px;
    ${mediaQueries('md')} {
      margin-right: 0px;
    }
  }

  ${(props) =>
    props.theme.name === 'men' &&
    css`
      border: none;
      padding: 15px;
      background: url(${BgPoint}) repeat;
      &:not(:last-child) {
        padding-right: 0;
        margin-right: 0;
      }
    `}
`;

export const Image = styled.div`
  position: relative;
  ${(props) =>
    props.theme.name === 'men' &&
    css`
      background-color: #131313;
      margin-bottom: 20px;
      height: 180px;

      .gatsby-image-wrapper {
        /* position: absolute !important;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%; */
        picture {
          img {
            position: absolute !important;
            transform: translate(-50%, -50%);
            top: 50% !important;
            left: 50% !important;
          }
        }
      }
    `}

  .gatsby-image-wrapper {
    & > div {
      ${(props) =>
        props.theme.name === 'women'
          ? `padding-bottom: 100% !important;`
          : `padding-bottom: 180px !important;`}
    }
    img {
      object-fit: contain !important;
    }
  }
  img {
    max-height: 105px;

    ${mediaQueries('md')} {
      max-height: 200px;
    }
  }
`;

export const Name = styled.h1`
  color: ${(props) => props.theme.relatedProduct.nameColor};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: normal;
  text-align:left;
`;

export const Description = styled.div`
  font-size: 11px;
  margin-bottom: 15px;
  color: ${(props) => props.theme.textColor};
  text-align:left;
`;

export const ProductLink = styled(Link)`
  display:inline-block;
  color: ${(props) => props.theme.relatedProduct.buttonColor};
  font-size: 11px;
  transition: 0.3s color;
  background: ${(props) => props.theme.relatedProduct.buttonBg};
  text-transform: uppercase;
  padding:5px 20px;
  border-radius:20px;

  &:hover {
    color: ${(props) => props.theme.relatedProduct.buttonHoverColor};
    text-decoration:none;
  }

`;

export const Detail = styled.div`
  ${(props) =>
  props.theme.name === 'men' &&
  css`
    text-align:right;
  `}
`;

export const Items = styled.div``;
