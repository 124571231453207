import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { onCollapseClick } from '../../state/reducers/products';

import Link from '../../components/Link';
import * as styles from './SlideBar.module.scss';
import LayoutContext from '../../context/LayoutContext';
import { ListStyled, PanelHeader, PanelContent } from './StyledSlideBar';
import { orderBySequence } from '../../utils/common';

const List = ({ items, parent }) => {
  const { t,i18n } = useTranslation();
  const layoutProps = useContext(LayoutContext);
  const { locale, type, gender } = layoutProps.pageContext;

  const handleRangePageNavigationClick = (item) => {
    //console.log(item)

    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'range_page_navigation',
      eventCategory: 'range page navigation',
      eventAction: 'Click',
      eventLabel: item.join('::'),
      range_page_category:item[0],
      range_page_filter:item[1],
      page_category: 'range page',
      site_language: i18n.language,
    });
  };

  return (
    <ListStyled>
      {orderBySequence(items).map((item, index) => {
        const name = item.localizations[locale].name;
        const genderPath = gender === 'men' ? '/men' : '';

        return (
          <li key={index}>
            <Link
              to={`${genderPath}/category/${type}/${item.slug}`}
              activeClassName="active"
              onClick={() => { handleRangePageNavigationClick([parent, name]) }}
            >
              {name}({item.count})
            </Link>
          </li>
        );
      })}
    </ListStyled>
  );
};

const Panel = ({ header, content, onClick, current }) => {
  const slideProps = useContext(SliderContext);

  const isCollapse = slideProps.current === current;

  return (
    <div>
      <PanelHeader
        onClick={onClick}
        className={`${isCollapse ? 'active' : ''}`}
      >
        {header}
      </PanelHeader>
      <PanelContent className={`${isCollapse ? 'active' : ''}`}>
        <List items={content} parent={header} />
      </PanelContent>
    </div>
  );
};

const SlideBar = ({ items }) => {
  const { collapseCurrent } = useSelector((state) => state.products);
  const { subType } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <SliderContext.Provider value={{ current: collapseCurrent }}>
      <div className={`${styles.Collapse}`}>
        {subType.map((item, index) => {
          return (
            <Panel
              key={index}
              current={index}
              header={t(item.name)}
              content={items[item.key]}
              onClick={() => dispatch(onCollapseClick(index))}
            />
          );
        })}
      </div>
    </SliderContext.Provider>
  );
};

export const SliderContext = createContext({});
export default SlideBar;
