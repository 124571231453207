import styled, { css } from 'styled-components';
import ImgArrowDown from '../../assets/images/global/red-arrow-down.png';
import ImgArrowNext from '../../assets/images/global/grey-arrow-right.png';

export const ListStyled = styled.ul`
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  ${(props) =>
    props.theme.name === 'men' &&
    `
    margin-left: 10px; 
    margin-bottom: 20px;
    `}

  li {
    list-style-position: inside;
    margin-bottom: 4px;

    &::before {
      ${(props) =>
        props.theme.name === 'women' ? 'content: "•"' : 'content: "＞"'};
      color: ${(props) => props.theme.primaryColor};
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: 0;
      margin-right: 3px;
    }

    a {
      color: ${(props) => props.theme.textColor};
      text-decoration: none;
      &:hover,
      &.active {
        color: ${(props) => props.theme.primaryColor};
      }
    }
  }
`;

export const PanelHeader = styled.div`
  cursor: pointer;
  color: #0b72b5;
  font-weight: bold;
  margin-bottom: 4px;
  text-transform: uppercase;
  position: relative;
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;

  ${(props) =>
    props.theme.name === 'men' &&
    css`
      background-color: #1a1a1a;
      color: #939292;
      padding: 5px;
      margin-bottom: 20px;

      &::after {
        content: url(${ImgArrowNext});
        position: absolute;
        right: 5px;
        color: ${props.theme.activeColor};
      }

      &.active {
        &::after {
          content: url(${ImgArrowDown});
        }
      }
    `}

  ${(props) =>
    props.theme.name === 'women' &&
    css`
      &::before {
        content: '▶';
        transform: rotate(90deg);
        position: relative;
        font-size: 8px;
        top: -1px;
        padding-right: 5px;
      }

      &.active {
        &::before {
          content: '▼';
        }
      }
    `}
`;

export const PanelContent = styled.div`
  height: 0;
  overflow: hidden;

  &.active {
    height: 100%;
  }
`;
